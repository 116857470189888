<template>
  <b-form-group
    label="İngilizce Başlık"
    label-for="en_title"
  >
    <b-form-input
      id="en_title"
      v-model="dataItem.en_title"
      placeholder="İngilizce Başlık"
    />
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'EnTitle',
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['productGroups/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped>

</style>
