<template>
  <b-card>
    <b-row>
      <b-col>
        <title-name />
      </b-col>
      <b-col>
        <en-title />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Product_groups/elements/Title.vue'
import EnTitle from '@/views/Admin/Defines/Product_groups/elements/Title_en.vue'

export default {
  name: 'CustomerTypeForm',
  components: {
    EnTitle,
    BCard,
    TitleName,
    BRow,
    BCol,
  },
}
</script>
